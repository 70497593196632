$(document).ready(function () {
  $(".reviews__slider").slick({
    slidesToShow: 10,
    arrows: false,
    dots: false,
    autoplay: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 3,
          vertical: true,
          verticalSwipe: true,
          autoplay: true,
          arrows: true,
        },
      },
    ],
  });
  $(".points__slider").slick({
    slidesToShow: 6,
    arrows: false,
    dots: false,
    autoplay: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 431,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  });
});

$(document).ready(function () {
  $(".image__slider").on("init", () => {
    $('.slick-slide[data-slick-index="-2"]').addClass("lt2");
    $('.slick-slide[data-slick-index="-1"]').addClass("lt1");
    $('.slick-slide[data-slick-index="1"]').addClass("gt1");
    $('.slick-slide[data-slick-index="2"]').addClass("gt2");
  });
  $(".image__slider")
    .slick({
      centerMode: true,
      centerPadding: 0,
      slidesToShow: 5,
      arrows: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 431,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    })
    .on("beforeChange", (event, slick, current, next) => {
      $(".slick-slide.gt2").removeClass("gt2");
      $(".slick-slide.gt1").removeClass("gt1");
      $(".slick-slide.lt1").removeClass("lt1");
      $(".slick-slide.lt2").removeClass("lt2");

      const lt2 = current < next && current > 0 ? current - 1 : next - 2;
      const lt1 = current < next && current > 0 ? current : next - 1;
      const gt1 = current < next || next === 0 ? next + 1 : current;
      const gt2 = current < next || next === 0 ? next + 2 : current + 1;

      $(`.slick-slide[data-slick-index="${lt2}"]`).addClass("lt2");
      $(`.slick-slide[data-slick-index="${lt1}"]`).addClass("lt1");
      $(`.slick-slide[data-slick-index="${gt1}"]`).addClass("gt1");
      $(`.slick-slide[data-slick-index="${gt2}"]`).addClass("gt2");

      // Clone processing when moving from 5 to 0
      if (current === 5 && next === 0) {
        $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass("lt2");
        $(`.slick-slide[data-slick-index="${current}"]`).addClass("lt1");
        $(`.slick-slide[data-slick-index="${current + 2}"]`).addClass("gt1");
        $(`.slick-slide[data-slick-index="${current + 3}"]`).addClass("gt2");
      }

      // Clone processing when moving from 0 to 5
      if (current === 0 && next === 5) {
        $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass("gt2");
        $(`.slick-slide[data-slick-index="${current}"]`).addClass("gt1");
        $(`.slick-slide[data-slick-index="${current - 2}"]`).addClass("lt1");
        $(`.slick-slide[data-slick-index="${current - 3}"]`).addClass("lt2");
      }

      console.log("beforeChange", current, ":", lt2, lt1, next, gt1, gt2);
    });
});

// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

//VIDEO
var video;
window.onload = function () {
  video = document.getElementById("myplayer");
};
function play() {
  video.play();
}
function end() {
  console.log("test");
  $(".play-btn").show();
}

$(document).on("click", ".play-btn", function () {
  $(this).hide();
  video.play();
});

// Burger Menu
function initHamburger() {
  const activeClass = "active";
  const $button = $(".burger__button");
  const $navContainer = $(".header__nav");
  const $navItems = $(".header__link");

  function toggleActiveClass() {
    [$button, $navContainer].forEach((item) => item.toggleClass(activeClass));
  }

  $button.click(toggleActiveClass);
  $navItems.click(toggleActiveClass);
}

$(document).ready(function () {
  initHamburger();
  initAnchorScrolling();
});
